import { Link } from "gatsby";
import * as React from "react";
import * as styles from "./footer.module.css";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.logoContainer}>
        <img src="/logo05.svg" alt="Logo" className={styles.logo} />
      </div>
      <div className={styles.info}>
        <div className={styles.infoSection}>
          <p className={styles.infoTitle}>小倉たんはらみ</p>
          <div className={styles.infoItem}>
            <Link to="/info" className={styles.link}>
              <p>　アクセス</p>
            </Link>
            <Link to="/pages" className={styles.link}>
              <p>　コラム</p>
            </Link>
            <Link to="/menu" className={styles.link}>
              <p>　メニュー</p>
            </Link>
          </div>
        </div>
        <div className={styles.infoSection}>
          <p className={styles.infoTitle}>概要</p>
          <div className={styles.infoItem}>
            <Link
              to="https://niku-nagaoka.com/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              <p>　肉のながおか</p>
            </Link>
            <Link to="/about" className={styles.link}>
              <p>　当店について</p>
            </Link>
            <Link to="/privacy" className={styles.link}>
              <p>　プライバシーポリシー</p>
            </Link>
          </div>
        </div>
        <div className={styles.infoSection}>
          <p className={styles.infoTitle}>ショップ</p>
          <div className={styles.infoItem}>
            <Link to="https://nikunagaoka.base.shop" className={styles.link}>
              <p>　公式ネットショップ</p>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
