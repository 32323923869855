// extracted by mini-css-extract-plugin
export var content = "Menu-module--content--9990e";
export var description = "Menu-module--description--9ff12";
export var image = "Menu-module--image--bb984";
export var imageWrapper = "Menu-module--imageWrapper--6cbad";
export var menu = "Menu-module--menu--5cf5a";
export var no = "Menu-module--no--8d3f2";
export var secondImage = "Menu-module--secondImage--0c06c";
export var secondText = "Menu-module--secondText--8266b";
export var textWrapper = "Menu-module--textWrapper--b7dff";
export var title = "Menu-module--title--31a63";