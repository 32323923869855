import * as React from "react";
import Footer from "./components/footer/footer";
import Header from "./components/Header101/Header";
import Slideshow from "./components/Slideshow2/Slideshow";
import "./layout.css";

const Layout = ({ children }) => {
  return (
    <>
      <Header
        title="小倉たんはらみ"
        logoImage="/logo02.svg"
        navItems={[
          {
            id: 1,
            name: "SNS",
            dropdown: true,
            subItems: [
              {
                id: 1,
                name: "instagram",
                link: "https://instagram.com/kokuratanharami2024/",
              },
              {
                id: 2,
                name: "LINE公式アカウント",
                link: "https://lin.ee/XNe7xF7",
              },
            ],
          },
          {
            id: 2,
            name: "コラム",
            link: "/pages",
            dropdown: false,
          },
          {
            id: 2,
            name: "公式ネットショップ",
            link: "https://nikunagaoka.base.shop/",
            dropdown: false,
          },
          {
            id: 3,
            name: "営業時間・アクセス",
            link: "/access",
            dropdown: false,
          },
        ]}
      />
      <Slideshow
        images={[
          { id: 2, src: "/images/index/yakiniku_top_1.jpg", alt: "Image 2" },
          { id: 3, src: "/images/index/top_2.jpg", alt: "Image 3" },
          { id: 1, src: "/images/index/top_3.jpg", alt: "Image 1" },
        ]}
      />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
