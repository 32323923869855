import * as React from "react";
import TitleUnderline from "../title_underline/TitleUnderline";
import * as styles from "./Menu.module.css";

const Menu = () => {
  return (
    <div className={styles.menu}>
      <TitleUnderline title="メニュー" color="#fff" underlineColor="#fff" />

      <div className={styles.content}>
        <div className={styles.imageWrapper}>
          <img
            src="/images/index/yakiniku_menu_1.jpg"
            alt="menu_1"
            className={styles.image}
          />
        </div>
        <div className={styles.textWrapper}>
          <h2 className={styles.title}>コースメニュー</h2>
          <p className={styles.description}>
            小倉たんはらみでは、以下の料理をコース形式にて提供しております
            <br />
            <br />
            牛刺
            <br />
            <br />
            白菜キムチ
            <br />
            <br />
            牛たん
            <br />
            上ミノ
            <br />
            <br />
            サガリ
            <br />
            ハラミ
            <br />
            <br />
            ホルモン盛り合わせ
            <br />
            <br />
          </p>
        </div>
      </div>

      <div className={styles.content}>
        <div className={`${styles.imageWrapper} ${styles.secondImage}`}>
          <img
            src="/images/index/bento_1.jpg"
            alt="bento_1"
            className={styles.image}
          />
        </div>
        <div className={`${styles.textWrapper} ${styles.secondText}`}>
          <h2 className={styles.title}>国産牛タン弁当</h2>
          <p className={styles.description}>
            ・国産牛タン弁当：2,160円(税込)
            <br />
            <br />
            　前日夕方5時まで 最小10個より受付
          </p>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.imageWrapper}>
          <img
            src="/images/index/bento_2.jpg"
            alt="bento_2"
            className={styles.image}
          />
        </div>
        <div className={styles.textWrapper}>
          <h2 className={styles.title}>国産牛ハラミ弁当</h2>
          <p className={styles.description}>
            ・国産牛ハラミ弁当：2,160円(税込)
            <br />
            <br />
            　前日夕方5時まで 最小10個より受付
          </p>
        </div>
      </div>
      <div className={styles.content}>
        <div className={`${styles.imageWrapper} ${styles.secondImage}`}>
          <img
            src="/images/index/bento_1.jpg"
            alt="bento_3"
            className={styles.image}
          />
        </div>
        <div className={`${styles.textWrapper} ${styles.secondText}`}>
          <h2 className={styles.title}>国産牛サガリ弁当</h2>
          <p className={styles.description}>
            ・国産牛サガリ弁当：2,160円(税込)
            <br />
            <br />
            　前日夕方5時まで 最小10個より受付
          </p>
        </div>
      </div>
    </div>
  );
};

export default Menu;
