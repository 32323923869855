import * as React from "react";
import Menu from "../components/menu/Menu";
import Seo from "../components/seo";
import Layout from "../layout";

const MenuPage = () => {
  return (
    <Layout>
      <Menu />
    </Layout>
  );
};

export default MenuPage;

export const Head = () => (
  <Seo description="小倉たんはらみについて" title="MENU" />
);
